import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LanguageDropdown from './language-dropdown';

type AdditionalProps = Partial<{
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
}>;

function BackButton() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (pathname === '/') {
    return <div />;
  }
  return <ChevronLeftIcon className="size-6" onClick={() => navigate(-1)} />;
}

export default function Header({
  left,
  center,
  right,
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & AdditionalProps) {
  return (
    <header className={`flex flex-row justify-between ${className}`} {...props}>
      {left ? left : <BackButton />}
      {center ? center : <div />}
      {right ? right : <LanguageDropdown />}
    </header>
  );
}
