import type {
  Timestamp,
  DocumentSnapshot,
  DocumentData,
  FirestoreDataConverter,
} from 'firebase/firestore';

export enum UserSexualPosition {
  NONE,
  TOP,
  BOTTOM,
  VERS,
  VERSTOP,
  VERSBOTTOM,
  SIDE,
  STRAIGHT_MALE,
  STRAIGHT_FEMALE,
}

export interface User {
  id: string;
  name: string;
  joinedAt: Timestamp;
  email: string;
  phoneNumber: string;
  birthday: Timestamp;
  profileImageUrl: string;
  profileThumbnailUrl: string;
  profileMessage: string;
  loyaltyPoint: number;
  experiencePoint: number;
  popularityPoints: number;
  sexualPosition: UserSexualPosition;
  height: number;
  weight: number;
  lastActiveAt: Timestamp;
}

export const userConverter: FirestoreDataConverter<Partial<User>> = {
  toFirestore: (user) => {
    delete user.id;
    return user;
  },
  fromFirestore: (snapshot: DocumentSnapshot<DocumentData>) => {
    const data = snapshot.data();

    if (!data) {
      throw new Error('Document does not exist');
    }

    return {
      id: snapshot.id,
      name: data.name,
      joinedAt: data.joinedAt as Timestamp,
      email: data.email,
      phoneNumber: data.phoneNumber,
      birthday: data.birthday as Timestamp,
      profileImageUrl: data.profileImageUrl,
      profileThumbnailUrl: data.profileThumbnailUrl,
      profileMessage: data.profileMessage,
      loyaltyPoint: data.loyaltyPoint,
      experiencePoint: data.experiencePoint,
      popularityPoints: data.popularityPoints,
      sexualPosition: data.sexualPosition,
      height: data.height,
      weight: data.weight,
      lastActiveAt: data.lastActiveAt as Timestamp,
    };
  },
};
