import { useObservableState, useObservableSuspense } from 'observable-hooks';
import { useMemo } from 'react';

import {
  User,
  UserProfile,
  UserSexualPosition,
  currentUserResource,
  observeUserProfileImageUrlById,
  signInWithPhoneNumber as signInWithPhoneNumber_FB,
  signOut,
  updateUserProfileById,
} from '../firebase';

export interface AuthenticationStateAuthenticated {
  status: 'authenticated';
  user: User;
  signOut: () => void;
}

export interface AuthenticationStateUnauthenticated {
  status: 'unauthenticated';
  signInWithPhoneNumber: (phoneNumber: string) => Promise<CodeVerifier>;
}

export type ProfileCompleter = {
  complete: (profile: Partial<UserProfile>) => Promise<void>;
};

export type CodeVerifier = {
  confirm: (verificationCode: string) => Promise<ProfileCompleter>;
};

export type AuthenticationState =
  | AuthenticationStateAuthenticated
  | AuthenticationStateUnauthenticated;

export { UserSexualPosition };
export type { UserProfile };

export function useAuthentication() {
  const user = useObservableSuspense(currentUserResource);

  const signInWithPhoneNumber = async (phoneNumber: string) => {
    const confirmationResult = await signInWithPhoneNumber_FB(phoneNumber);
    const codeVerifier: CodeVerifier = {
      confirm: async (verificationCode: string) => {
        const userCredential = await confirmationResult.confirm(verificationCode);
        const profileCompleter: ProfileCompleter = {
          complete: (profile) => {
            return updateUserProfileById(userCredential.user.uid, profile);
          },
        };

        return profileCompleter;
      },
    };

    return codeVerifier;
  };

  return {
    status: user ? 'authenticated' : 'unauthenticated',
    user,
    signOut,
    signInWithPhoneNumber,
  } as AuthenticationState;
}

export function useUserProfileImageUrl(userId: string, thumbnail?: boolean) {
  const url$ = useMemo(
    () => observeUserProfileImageUrlById(userId, thumbnail),
    [userId, thumbnail]
  );

  return useObservableState(url$);
}
