import classNames from 'classnames';
import { initializeApp } from 'firebase/app';
import {
  User as FirebaseUser,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber as signInWithPhoneNumber_FB,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  DocumentSnapshot,
  getFirestore,
  setDoc,
  getDoc,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { getStorage, uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import $ from 'jquery';
import { ObservableResource } from 'observable-hooks';
import { map, switchMap, Observable, of, BehaviorSubject, firstValueFrom, from } from 'rxjs';
import { ulid } from 'ulid';

import { fromRef } from './fromRef';
import { User, UserSexualPosition } from './models';
import { getUserRefById } from './references';
import { resizeImage } from './utilities';
import './verifier-container.css';

const firebaseConfig = {
  apiKey: 'AIzaSyDKJp11RfdU9VZU8K9Aq4l1_-8KSbJb0wI',
  authDomain: 'docking-4a2a3.firebaseapp.com',
  databaseURL: 'https://docking-4a2a3-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'docking-4a2a3',
  storageBucket: 'docking-4a2a3.appspot.com',
  messagingSenderId: '39655773797',
  appId: '1:39655773797:web:c1d444319f53045216988c',
  measurementId: 'G-E0S3SDN772',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app);
// connectAuthEmulator(auth, "http://127.0.0.1:9099");

// utilities
interface VerifierManager {
  verifier: RecaptchaVerifier;
  show: () => void;
  hide: () => void;
  clear: () => void;
  verify: () => Promise<string>;
}

const verifierManager$ = new BehaviorSubject<VerifierManager | null>(null);

(async (): Promise<VerifierManager> => {
  const verifierContainerId = ulid();
  const verifierContainer = $('<div></div>')
    .attr('id', verifierContainerId)
    .addClass(classNames('verifier-container'))
    .appendTo('body');

  const onVerifcationCompleted = () => {
    hide();
  };

  const onVerifcationExpired = () => {
    window.alert('Recaptcha expired. Please try again.');
    (verifier as unknown as { recaptcha: { reset: () => void } }).recaptcha.reset();
    show();
  };

  const verifier = new RecaptchaVerifier(auth, verifierContainer[0], {
    size: 'normal',
    callback: onVerifcationCompleted,
    'expired-callback': onVerifcationExpired,
  });

  await verifier.render();

  const show = () => {
    verifierContainer
      .removeClass('verifier-container--fade-out')
      .addClass('verifier-container--fade-in');
  };

  const hide = () => {
    verifierContainer
      .removeClass('verifier-container--fade-in')
      .addClass('verifier-container--fade-out');
  };

  const clear = () => {
    if (!verifier) {
      return;
    }
    verifierContainer.empty();
    verifier.clear();
  };

  return {
    verifier,
    show,
    hide,
    verify: () => verifier.verify(),
    clear,
  };
})()
  .then((verifierManager) => {
    verifierManager$.next(verifierManager);
  })
  .catch((error) => {
    verifierManager$.error(error);
    console.error(error);
    alert('Failed to initialize RecaptchaVerifier. Please contact the administrator.');
    throw error;
  });

// internal observables
const firebaseUser$ = new Observable<FirebaseUser | null>((subscriber) => {
  const unsubscribe = onAuthStateChanged(
    auth,
    subscriber.next.bind(subscriber),
    subscriber.error.bind(subscriber),
    subscriber.complete.bind(subscriber)
  );
  return { unsubscribe };
});

// external observables
export const currentUser$ = firebaseUser$.pipe(
  map((user) => {
    if (!user) {
      // 디버그용
      // user = { uid: 'CIT7O1CTgpgGODUbArx6hDQPSMl1' } as FirebaseUser;
      return of(null) as Observable<DocumentSnapshot<Partial<User>> | null>;
    }

    const docRef = getUserRefById(firestore, user.uid);

    return fromRef(docRef) as Observable<DocumentSnapshot<Partial<User>> | null>;
  }),
  switchMap((snap$) => snap$),
  map((snap) => {
    if (snap === null) {
      return null;
    }

    if (!snap.exists()) {
      return null;
    } else {
      return snap.data();
    }
  })
);

export const currentUserResource = new ObservableResource(currentUser$);

export const authenticated$ = firebaseUser$.pipe(map((user) => !!user));

export async function signInWithPhoneNumber(phoneNumber: string) {
  const verifierManager = await firstValueFrom(verifierManager$);
  if (!verifierManager) {
    throw new Error('RecaptchaVerifier not initialized or failed to initialize.');
  }

  try {
    verifierManager.show();
    await verifierManager.verify();

    const confirmationResult = await signInWithPhoneNumber_FB(
      auth,
      phoneNumber,
      verifierManager.verifier
    );

    verifierManager.hide();
    return confirmationResult;
  } catch (error) {
    alert(error);
    throw error;
  } finally {
    verifierManager.hide();
  }
}

export { UserSexualPosition };
export type { User };

export type UserProfile = {
  name: string;
  profileImage: File;
  sexualPosition: UserSexualPosition;
  height: number;
  weight: number;
};

export async function updateUserProfileById(
  userId: string,
  { profileImage, ...profile }: Partial<UserProfile>
) {
  const userRef = getUserRefById(firestore, userId);
  const userSnapshot = await getDoc(userRef);

  const user: Partial<User> = {
    ...profile,
  };

  if (!userSnapshot.exists()) {
    user.joinedAt = serverTimestamp() as Timestamp;
    user.lastActiveAt = serverTimestamp() as Timestamp;
  }

  if (profileImage) {
    const profileImageRef = ref(storage, `profileImages/${userId}`);
    const profileThumbnailImageRef = ref(storage, `profileThumbnailImages/${userId}`);

    const profileImageBlob = await resizeImage(profileImage, 512, 512);
    const profileThumbnailImageBlob = await resizeImage(profileImage, 128, 128);

    const profileImageuUploadResult = await uploadBytes(profileImageRef, profileImageBlob);
    const profileThumbnailImageUploadResult = await uploadBytes(
      profileThumbnailImageRef,
      profileThumbnailImageBlob
    );

    user.profileImageUrl = profileImageuUploadResult.ref.fullPath;
    user.profileThumbnailUrl = profileThumbnailImageUploadResult.ref.fullPath;
  }

  await setDoc(userRef, user);
}

export function signOut() {
  return auth.signOut();
}

export function observeUserProfileImageUrlById(userId: string, thumbnail?: boolean) {
  const userRef = getUserRefById(firestore, userId);
  const user$ = fromRef(userRef) as Observable<DocumentSnapshot<Partial<User>> | null>;
  const downloadUrl$ = user$.pipe(
    map((snapshot) => {
      if (!snapshot) {
        return of(null);
      } else if (!snapshot.exists()) {
        return of(null);
      }

      const data = snapshot.data();
      const url = thumbnail ? data.profileThumbnailUrl : data.profileImageUrl;
      if (!url) {
        return of(null);
      }

      const imgRef = ref(storage, url);
      return from(getDownloadURL(imgRef));
    }),
    switchMap((x) => x)
  );

  return downloadUrl$;
}
