export const DEVICES = 'devices';
export const STORES = 'stores';
export const TABLES = 'tables';
export const TABLE_USAGES = 'tableUsages';
export const PRODUCT_CATEGORIES = 'productCategories';
export const PRODUCTS = 'products';
export const PRODUCT_ORDERS = 'productOrders';
export const RESERVATIONS = 'reservations';
export const RESERVATION_HISTORIES_ARRIVED = 'reservationHistoriesArrived';
export const RESERVATION_HISTORIES_CANCELLED = 'reservationHistoriesCancelled';
export const USERS = 'users';
