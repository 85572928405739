import styles from './gradient-background.module.css';

type Props = {
  mode?: 'simple' | 'default';
};

export default function GradientBackground({ mode = 'simple' }: Props) {
  return (
    <div
      className={`${styles['gradient-container']} ${mode === 'simple' ? styles['simple'] : styles['default']}`}
    >
      <div className={`${styles.gradient} ${styles.gradient1}`} />
      <div className={`${styles.gradient} ${styles.gradient2}`} />
      <div className={`${styles.gradient} ${styles.gradient3}`} />
      <div className={`${styles.gradient} ${styles.gradient4}`} />
    </div>
  );
}
