import { useRouteError } from 'react-router-dom';

// export default function ErrorFallback({
//   error,
//   resetErrorBoundary,
// }: FallbackProps) {
export default function ErrorFallback() {
  const error = useRouteError() as { statusText: string; message: string };

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mb-4 text-7xl font-bold">Oops!</h1>
      <p className="mb-3">Sorry, an unexpected error has occurred.</p>
      <p className="text-gray-400">
        <i>{error.message}</i>
      </p>
      {/* <button
        className="h-14 m-4 rounded-full text-xl bg-gradient-to-b from-indigo-600 to-indigo-700 text-white font-bold"
        // onClick={resetErrorBoundary}
      >
        Retry
      </button> */}
    </div>
  );
}
