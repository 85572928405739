import BackgroundGradient from '../../components/gradient-background';
import Header from '../../components/header';
import {
  AuthenticationStateAuthenticated,
  useAuthentication,
  useUserProfileImageUrl,
} from '../../hooks';

export default function Home() {
  const { user } = useAuthentication() as AuthenticationStateAuthenticated;
  const profileThumbnailUrl = useUserProfileImageUrl(user.id, true);

  return (
    <div className="flex h-full max-w-md flex-col items-stretch justify-start place-self-center px-8">
      <BackgroundGradient mode="default" />
      <BackgroundGradient mode="simple" />
      <Header className="my-2" />
      <section className="mb-8 flex flex-row items-stretch gap-2">
        <div className="avatar placeholder flex-col gap-2 text-center">
          <div className="w-16 rounded-full bg-neutral text-neutral-content">
            {profileThumbnailUrl ? (
              <img src={profileThumbnailUrl} alt="profile thumbnail" />
            ) : (
              <span className="text-xl font-bold">{user.name[0]}</span>
            )}
          </div>
          <span className="text-sm font-bold">{`${user.name}`}</span>
        </div>
        <div className="flex grow flex-col justify-evenly">
          <div className="flex flex-row justify-between ">
            <div className="flex flex-1 flex-col items-center">
              <span className="text-lg font-bold">1위</span>
              <span className="text-xs">인기 순위</span>
            </div>
            <div className="flex flex-1 flex-col items-center">
              <span className="text-lg font-bold">1,105</span>
              <span className="text-xs">누적 호감</span>
            </div>
            <div className="flex flex-1 flex-col items-center">
              <span className="text-lg font-bold">105,100</span>
              <span className="text-xs">적립금</span>
            </div>
          </div>
        </div>
      </section>

      <div
        className="card mb-8 bg-gradient-to-br from-cyan-600 via-fuchsia-600 to-pink-600 text-primary-content 
before:absolute before:inset-0 before:size-full before:translate-y-3 before:bg-gradient-to-br before:from-cyan-600/80 before:via-fuchsia-600/80 before:to-pink-600/80 before:blur-lg"
      >
        <div className="card-body z-10 p-8 text-sm">
          <h2 className="card-title">매장 입장 하기 🚀</h2>
          <p>
            매장 입장을 위해 QR코드를 스캔하고 개인화된 명찰을 받아주세요. 이 명찰로 매장에서
            제공되는 다양한 매칭 컨텐츠를 원활하게 즐기실 수 있답니다. 🏷️👍
          </p>
        </div>
      </div>

      <div className="card mb-8 bg-white/15 text-primary-content shadow-lg shadow-white/15">
        <div className="card-body">
          <h2 className="card-title">금일 뜨거운 인기 순위!</h2>
          <p>지금 당신이 꼭 확인해야 할 금일 실시간 TOP 3 랭킹입니다.</p>
          <div className="card-actions justify-end">
            <button className="btn btn-ghost">전체 순위 확인하기</button>
          </div>
        </div>
      </div>

      <div className="card mb-8 bg-white/15 text-primary-content shadow-lg shadow-white/15">
        <div className="card-body z-10 p-8 text-sm">
          <h2 className="card-title">이달의 핫한 주인공들!</h2>
          <p>이번 달 동안 받은 호감의 총합으로 선정된 TOP 3 주인공 소개</p>
          <div className="card-actions justify-end">
            <button className="btn btn-ghost">전체 랭킹 보기</button>
          </div>
        </div>
      </div>
    </div>
  );
}
