import BackgroundGradient from '../../components/gradient-background';
import { useState } from 'react';
import { CodeVerifier, ProfileCompleter, useAuthentication } from '../../hooks';
import PhoneNumber from './phone-number';
import CodeVerification from './code-verification';
import { Navigate, useNavigate } from 'react-router-dom';
import ProfileCompletion from './profile-completion';
import Header from '../../components/header';

export default function Auth() {
  const navigate = useNavigate();
  const state = useAuthentication();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [codeVerifier, setCodeVerifier] = useState<CodeVerifier | null>(null);
  const [profileCompleter, setProfileCompleter] = useState<ProfileCompleter | null>(null);

  return (
    <div className="my-16 flex h-full w-full max-w-md flex-col items-stretch justify-between place-self-center px-8">
      <BackgroundGradient />
      <Header
        left={
          <div className="bottom-0 place-self-center">
            <ul className="flex flex-row gap-2">
              <li
                className={`h-1.5 w-2 rounded bg-white/35 ${!phoneNumber && !codeVerifier && !profileCompleter && state.status === 'unauthenticated' && 'w-4 bg-white/90'}`}
              />
              <li
                className={`h-1.5 w-2 rounded bg-white/35 ${phoneNumber && codeVerifier && !profileCompleter && state.status === 'unauthenticated' && 'w-4 bg-white/90'}`}
              />
              <li
                className={`h-1.5 w-2 rounded bg-white/35 ${phoneNumber && codeVerifier && profileCompleter && state.status === 'unauthenticated' && 'w-4 bg-white/90'}`}
              />
            </ul>
          </div>
        }
        className="mb-3 -mt-10"
      />
      {!phoneNumber && !codeVerifier && !profileCompleter && state.status === 'unauthenticated' && (
        <PhoneNumber
          onSubmit={async (phoneNumber) => {
            const result = await state.signInWithPhoneNumber(phoneNumber);
            setCodeVerifier(result);
            setPhoneNumber(phoneNumber);
          }}
        />
      )}
      {phoneNumber && codeVerifier && !profileCompleter && state.status === 'unauthenticated' && (
        <CodeVerification
          phoneNumber={phoneNumber}
          onSubmit={async (verificationCode) => {
            const profileCompleter = await codeVerifier.confirm(verificationCode);
            setProfileCompleter(profileCompleter);
          }}
        />
      )}
      {phoneNumber && codeVerifier && profileCompleter && state.status === 'unauthenticated' && (
        <ProfileCompletion
          onSubmit={async (profile) => {
            await profileCompleter.complete(profile);
            navigate('/', { replace: true });
          }}
        />
      )}
      {state.status === 'authenticated' && <Navigate to="/" replace />}
    </div>
  );
}
