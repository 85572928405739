import { ErrorMessage, Field, Form, Formik } from 'formik';
import type { FieldProps } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { UserProfile, UserSexualPosition } from '../../hooks';

type Values = Partial<UserProfile>;

type Props = {
  onSubmit?: (values: Values) => Promise<void>;
};

export default function ProfileCompletion({ onSubmit }: Props) {
  const { t } = useTranslation();

  const sexualPositions = useMemo(
    () => ({
      [UserSexualPosition.NONE]: '성향을 선택하세요',
      [UserSexualPosition.TOP]: '탑',
      [UserSexualPosition.BOTTOM]: '바텀',
      [UserSexualPosition.VERS]: '올',
      [UserSexualPosition.VERSTOP]: '올탑',
      [UserSexualPosition.VERSBOTTOM]: '올바텀',
      [UserSexualPosition.SIDE]: '비선호',
      [UserSexualPosition.STRAIGHT_MALE]: '이성애자 남성',
      [UserSexualPosition.STRAIGHT_FEMALE]: '이성애자 여성',
    }),
    []
  );

  const validationSchema = useMemo(() => {
    return Yup.object({
      name: Yup.string()
        .defined()
        .required('이름은 필수에요! 😊')
        .max(50, '이름은 50자를 넘을 수 없어요! 🙅‍♂️'),
      // email: Yup.string()
      //   .email('유효한 이메일 주소를 입력해주세요! 📧')
      //   .required('이메일은 필수랍니다! 💌'),
      //   birthday: Yup.date()
      //     .required('생일을 입력해주세요! 🎂')
      //     .max(new Date(), '미래에서 오셨나요? 생일이 오늘 날짜보다 뒤일 수는 없어요! 🚫'),
      sexualPosition: Yup.mixed()
        .required('성향을 선택해주세요! 💘')
        .oneOf(Object.keys(UserSexualPosition), '유효하지 않은 성적 취향이에요! 🚫'),
      height: Yup.number()
        .required('키를 입력해주세요! 📏')
        .min(0, '키는 음수가 될 수 없어요! ⬇️')
        .max(300, '키가 300cm를 넘을 수는 없어요! 📏'),
      weight: Yup.number()
        .required('몸무게를 입력해주세요! ⚖️')
        .min(0, '몸무게는 음수가 될 수 없어요! ⚖️')
        .max(500, '몸무게가 500kg를 넘을 수는 없어요! 🚫'),
    });
  }, [t]);

  const initialValues = useMemo(() => {
    const values: Values = {
      name: '',
      weight: 0,
      height: 0,
      profileImage: undefined,
      sexualPosition: UserSexualPosition.NONE,
    };
    return values;
  }, []);

  return (
    <>
      <article className="prose mb-16">
        <h1>프로필 완성하기, 마지막 단계에요! 🌟</h1>
        <p>
          거의 다 왔어요! 아래 양식을 채워 주시면 도킹에서의 새로운 만남이 시작됩니다. 몇 분 안
          걸려요, 잠시만 시간을 내주세요! 🙌🎉
        </p>
      </article>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            await onSubmit?.(values);
          } catch (err) {
            console.dir(err);
            throw err;
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
      >
        {({ isValid, touched, isSubmitting }) => (
          <Form className="mb-16 flex flex-col">
            <Field name="profileImage">
              {({ field: { name }, form }: FieldProps) => (
                <div className="avatar placeholder mb-12 place-self-center">
                  <div className="input-bordered flex size-36 flex-col items-center justify-center rounded-full border-[1px] border-solid">
                    {form.values.profileImage && (
                      <img
                        src={URL.createObjectURL(form.values.profileImage)}
                        className="absolute size-full rounded-full"
                      />
                    )}
                    {!form.values.profileImage && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="absolute bottom-0 left-0 right-0 top-0 m-0 text-base-content/[0.625]"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                    {!form.values.profileImage && (
                      <div className="absolute bottom-0 left-0 right-0 top-0 m-0 flex flex-col items-center justify-center gap-3 rounded-full bg-black/[0.625]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                          />
                        </svg>
                        <span>프로필 이미지</span>
                      </div>
                    )}
                    {form.values.profileImage && (
                      <div
                        className="absolute bottom-0 left-0 right-0 top-0 m-0 flex flex-col items-center justify-center gap-3 rounded-full bg-black/[0.625]"
                        onClick={async () => {
                          if (confirm('선택한 사진을 삭제하시겠습니까?')) {
                            await form.setFieldValue(name, undefined);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                        <span>삭제</span>
                      </div>
                    )}
                    {!form.values.profileImage && (
                      <input
                        type="file"
                        id="profileImage"
                        name={name}
                        accept="image/*"
                        className="text-transparent file:absolute file:left-0 file:top-0 file:size-full file:border-none file:bg-transparent file:text-transparent"
                        onChange={(e) => {
                          const file = e.target.files![0];
                          form.setFieldValue(name, file);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </Field>

            <Field name="name">
              {({ field, meta }: FieldProps) => (
                <label className="form-control mb-4">
                  <label
                    className={`input input-bordered flex items-center gap-2 ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`h-6 w-6 ${meta.touched && meta.error && 'stroke-error'} ${meta.touched && !meta.error && 'stroke-success'}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                    <input
                      type="text"
                      id="name"
                      className={`grow ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                      placeholder="이름 혹은 닉네임"
                      disabled={isSubmitting}
                      {...field}
                    />
                  </label>
                  <ErrorMessage
                    name="name"
                    render={(errMsg) => (
                      <div className="label">
                        <span className="label-text-alt text-error">{errMsg}</span>
                      </div>
                    )}
                  />
                </label>
              )}
            </Field>

            {/* <Field name="email">
              {({ field, meta }: FieldProps) => (
                <label className="form-control mb-4">
                  <label
                    className={`input input-bordered flex items-center gap-2 ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className={`h-6 w-6 ${meta.touched && meta.error && 'stroke-error'} ${meta.touched && !meta.error && 'stroke-success'}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                    <input
                      type="email"
                      id="email"
                      className={`grow ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                      placeholder="이메일"
                      disabled={isSubmitting}
                      {...field}
                    />
                  </label>
                  <ErrorMessage
                    name="email"
                    render={(errMsg) => (
                      <div className="label">
                        <span className="label-text-alt text-error">{errMsg}</span>
                      </div>
                    )}
                  />
                </label>
              )}
            </Field> */}

            {/* <Field name="birthday">
              {({ field, meta }: FieldProps) => (
                <label className="form-control mb-4">
                  <label
                    className={`input input-bordered flex items-center gap-2 ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`h-6 w-6 ${meta.touched && meta.error && 'stroke-error'} ${meta.touched && !meta.error && 'stroke-success'}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z"
                      />
                    </svg>
                    <input
                      type="date"
                      id="birthday"
                      className={`h-full w-full ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'} ${field.value && 'has-value'}`}
                      placeholder="생년월일"
                      disabled={isSubmitting}
                      {...field}
                    />
                  </label>
                  <ErrorMessage
                    name="birthday"
                    render={(errMsg) => (
                      <div className="label">
                        <span className="label-text-alt text-error">{errMsg}</span>
                      </div>
                    )}
                  />
                </label>
              )}
            </Field> */}

            <Field name="sexualPosition">
              {({ field, meta }: FieldProps) => {
                return (
                  <label className="form-control mb-4">
                    <label
                      className={`input input-bordered flex items-center gap-2 pr-0 ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`h-6 w-6 ${meta.touched && meta.error && 'stroke-error'} ${meta.touched && !meta.error && 'stroke-success'}`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                        />
                      </svg>
                      <select
                        className={`select m-0 h-full w-full border-none bg-transparent p-0 outline-none focus:border-none focus:outline-none ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                        {...field}
                      >
                        {Object.entries(sexualPositions).map(([value, label]) => (
                          <option
                            key={value}
                            value={value}
                            className={`${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                            disabled={value === UserSexualPosition.NONE.toString()}
                          >
                            {label}
                          </option>
                        ))}
                      </select>
                    </label>
                    <ErrorMessage
                      name="sexualPosition"
                      render={(errMsg) => (
                        <div className="label">
                          <span className="label-text-alt text-error">{errMsg}</span>
                        </div>
                      )}
                    />
                  </label>
                );
              }}
            </Field>

            <Field name="height">
              {({ field, meta }: FieldProps) => (
                <label className="form-control mb-4">
                  <label
                    className={`input input-bordered flex items-center gap-2 ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`h-7 w-7 ${meta.touched && meta.error && 'stroke-error'} ${meta.touched && !meta.error && 'stroke-success'}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                    <input
                      type="number"
                      id="height"
                      className={`grow ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                      placeholder="키"
                      disabled={isSubmitting}
                      {...field}
                    />
                    <span
                      className={`${meta.touched && meta.error && 'text-error'} ${meta.touched && !meta.error && 'text-success'}`}
                    >
                      cm
                    </span>
                  </label>
                  <ErrorMessage
                    name="height"
                    render={(errMsg) => (
                      <div className="label">
                        <span className="label-text-alt text-error">{errMsg}</span>
                      </div>
                    )}
                  />
                </label>
              )}
            </Field>

            <Field name="weight">
              {({ field, meta }: FieldProps) => (
                <label className="form-control mb-4">
                  <label
                    className={`input input-bordered flex items-center gap-2 ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`h-7 w-7 rotate-90 ${meta.touched && meta.error && 'stroke-error'} ${meta.touched && !meta.error && 'stroke-success'}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                    <input
                      type="number"
                      id="weight"
                      className={`grow ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                      placeholder="몸무게"
                      disabled={isSubmitting}
                      {...field}
                    />
                    <span
                      className={`${meta.touched && meta.error && 'text-error'} ${meta.touched && !meta.error && 'text-success'}`}
                    >
                      kg
                    </span>
                  </label>
                  <ErrorMessage
                    name="weight"
                    render={(errMsg) => (
                      <div className="label">
                        <span className="label-text-alt text-error">{errMsg}</span>
                      </div>
                    )}
                  />
                </label>
              )}
            </Field>
            <button
              type="submit"
              className="btn-gradient btn btn-primary mb-4"
              disabled={(touched && !isValid) || isSubmitting}
            >
              {!isSubmitting ? '완료' : <span className="loading loading-spinner loading-sm" />}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}
