import * as Yup from 'yup';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type Props = {
  phoneNumber: string;
  onSubmit?: (verificationCode: string) => Promise<void>;
};

export default function CodeVerification({ phoneNumber, onSubmit }: Props) {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    return Yup.object({
      verificationCode: Yup.string()
        .matches(/^[0-9]{5,7}$/, '5에서 7자리 숫자만 입력 가능합니다.')
        .required(t(`codeRequiredError`)),
    });
  }, [t]);

  return (
    <>
      <article className="prose mb-16">
        <h1>{`문자 발송 완료!`}</h1>
        <p>{`${phoneNumber}로 확인 코드를 보내드렸어요 📲 문자 메시지를 확인하신 후, 코드를 입력해 주세요. 😊`}</p>
      </article>
      <Formik
        initialValues={{ verificationCode: '' }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          try {
            await onSubmit?.(values.verificationCode);
          } catch (err) {
            console.error(err);
            setErrors({ verificationCode: (err as Error).message });
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
      >
        {({ touched, isValid, isSubmitting }) => (
          <Form className="mb-16 flex flex-col">
            <Field name="verificationCode">
              {({ field, meta }: FieldProps) => (
                <label className="form-control mb-4">
                  <label
                    className={`input input-bordered flex max-w-md items-center ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                  >
                    <input
                      type="text"
                      id="verificationCode"
                      className={`grow text-center text-xl placeholder:text-center ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                      placeholder="인증코드"
                      disabled={isSubmitting}
                      {...field}
                    />
                  </label>
                  <ErrorMessage
                    name="verificationCode"
                    render={(errMsg) => (
                      <div className="label">
                        <span className="label-text-alt text-error">{errMsg}</span>
                      </div>
                    )}
                  />
                </label>
              )}
            </Field>
            <button
              type="submit"
              className="btn-gradient btn btn-primary"
              disabled={(touched && !isValid) || isSubmitting}
            >
              {!isSubmitting ? '인증' : <span className="loading loading-spinner loading-sm" />}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}
