import { DocumentData, DocumentReference, Firestore, doc } from 'firebase/firestore';
import { USERS } from './collections';
import { User, userConverter } from './models';

export function getUserRefById(
  firestore: Firestore,
  userId: string
): DocumentReference<Partial<User>, DocumentData> {
  return doc(firestore, USERS, userId).withConverter(userConverter);
}
