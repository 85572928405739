import * as Yup from 'yup';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type Props = {
  onSubmit?: (phoneNumber: string) => Promise<void>;
};

export default function PhoneNumber({ onSubmit }: Props) {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object({
      phoneNumber: Yup.string().required(t(`requiredError`)),
    });
  }, [t]);

  return (
    <>
      <article className="prose mb-16">
        <h1>{t(`startWithPhoneNumberTitle`)}</h1>
        <p>{t(`startWithPhoneNumberDescription`)}</p>
      </article>
      <Formik
        initialValues={{ phoneNumber: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            if (values.phoneNumber.startsWith('010')) {
              const correctedPhoneNumber = `+82${values.phoneNumber.slice(1)}`;
              await onSubmit?.(correctedPhoneNumber);
            } else {
              await onSubmit?.(values.phoneNumber);
            }
          } catch (err) {
            console.dir(err);
            throw err;
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
      >
        {({ isValid, touched, isSubmitting }) => (
          <Form className="mb-16 flex flex-col">
            <Field name="phoneNumber">
              {({ field, meta }: FieldProps) => (
                <label className="form-control mb-4">
                  <label
                    className={`input input-bordered flex items-center gap-2 ${meta.touched && meta.error && 'input-error'} ${meta.touched && !meta.error && 'input-success'}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`h-6 w-6 ${meta.touched && meta.error && 'stroke-error'} ${meta.touched && !meta.error && 'stroke-success'}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                      />
                    </svg>
                    <input
                      type="tel"
                      id="phoneNumber"
                      className={`grow ${meta.touched && meta.error && 'text-error placeholder:text-error'} ${meta.touched && !meta.error && 'text-success placeholder:text-success'}`}
                      placeholder={t(`phoneNumberInputPlaceholder`)}
                      disabled={isSubmitting}
                      {...field}
                    />
                  </label>
                  <ErrorMessage
                    name="phoneNumber"
                    render={(errMsg) => (
                      <div className="label">
                        <span className="label-text-alt text-error">{errMsg}</span>
                      </div>
                    )}
                  />
                </label>
              )}
            </Field>
            <button
              type="submit"
              className="btn-gradient btn btn-primary"
              disabled={(touched && !isValid) || isSubmitting}
            >
              {!isSubmitting ? (
                t(`sendVerificationCodeButton`)
              ) : (
                <span className="loading loading-spinner loading-sm" />
              )}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}
