import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  SnapshotListenOptions,
  onSnapshot,
} from 'firebase/firestore';
import { Observable } from 'rxjs';

const DEFAULT_OPTIONS = { includeMetadataChanges: false };

export function fromRef<T = DocumentData>(
  ref: DocumentReference<T>
): Observable<DocumentSnapshot<T>>;
export function fromRef<T = DocumentData>(ref: Query<T>): Observable<QuerySnapshot<T>>;
export function fromRef<T = DocumentData>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
  options: SnapshotListenOptions = DEFAULT_OPTIONS
): Observable<DocumentSnapshot<T> | QuerySnapshot<T>> {
  if (ref.type === 'document' || ref.type === 'query') {
    return new Observable((subscriber) => {
      const unsubscribe = onSnapshot(ref, options, {
        next: subscriber.next.bind(subscriber),
        error: subscriber.error.bind(subscriber),
        complete: subscriber.complete.bind(subscriber),
      });
      return { unsubscribe };
    });
  } else {
    throw new Error('Unknown reference type');
  }
}
