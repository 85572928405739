import { ChevronDownIcon, LanguageIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function LanguageDropdown() {
  const { i18n } = useTranslation();

  return (
    <div className="dropdown dropdown-end dropdown-bottom z-50">
      <div tabIndex={0} role="button" className="flex items-center gap-1">
        <LanguageIcon className="size-6" />
        <ChevronDownIcon className="size-4" />
      </div>
      <ul tabIndex={0} className="menu dropdown-content mt-4 w-32 rounded-box bg-base-200">
        <li>
          <a href="#" tabIndex={1} onClick={() => i18n.changeLanguage('ko')}>
            🇰🇷 한국어
          </a>
        </li>
        <li>
          <a href="#" tabIndex={1} onClick={() => i18n.changeLanguage('en')}>
            🇺🇸 English
          </a>
        </li>
        <li>
          <a href="#" tabIndex={1} onClick={() => i18n.changeLanguage('ja')}>
            🇯🇵 日本語
          </a>
        </li>
        <li>
          <a href="#" tabIndex={1} onClick={() => i18n.changeLanguage('zh')}>
            🇨🇳 中文
          </a>
        </li>
        <li>
          <a href="#" tabIndex={1} onClick={() => i18n.changeLanguage('th')}>
            🇹🇭 ไทย
          </a>
        </li>
      </ul>
    </div>
  );
}
