import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './i18n';
import BackgroundGradient from './components/gradient-background';
import Auth from './routes/auth';
import ErrorFallback from './routes/error-fallback';
import Home from './routes/home';

import './index.css';

function AppLoading() {
  return (
    <>
      <BackgroundGradient mode="simple" />
      <article className="prose flex h-full w-full flex-col items-center justify-center place-self-center ">
        <h1>Initailizing...</h1>
        <p>
          <span className="loading loading-spinner loading-lg" />
        </p>
      </article>
    </>
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<AppLoading />}>
        <Routes>
          <Route path="/" Component={Home} errorElement={<ErrorFallback />} />
          <Route path="auth" Component={Auth} errorElement={<ErrorFallback />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);
